@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  background-color: #f6f6f6;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Calibri", sans-serif, "Roboto";
}
